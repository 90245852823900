import React from 'react';

import { HeroLarge } from './HeroLarge';
import { HeroSmall } from './HeroSmall';

export const Hero = () => (
  <>
    <HeroSmall />
    <HeroLarge />
  </>
);
