import React from 'react';

import { CashLarge } from './CashLarge';
import { CashSmall } from './CashSmall';

export const Cash = () => (
  <>
    <CashSmall />
    <CashLarge />
  </>
);
