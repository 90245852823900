import { Badge } from 'component-library/components/badge/Badge';
import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { colors } from 'component-library/styles/colors';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionSmall } from 'page-components/shared/section/SectionSmall';
import React from 'react';

import { badge, body, heading } from './content';

export const CashSmall = () => (
  <SectionSmall
    backgroundColor={colors.green}
    image={<StaticImage src="../images/cash.webp" alt="cash-bucket" />}
    withImagePadding
  >
    <Badge marginBottom={24}>{badge}</Badge>
    <Heading marginBottom={16} variant={4}>
      {heading}
    </Heading>
    <Body variant={3}>{body}</Body>
  </SectionSmall>
);
