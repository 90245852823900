import React from 'react';

import { GrowthLarge } from './GrowthLarge';
import { GrowthSmall } from './GrowthSmall';

export const Growth = () => (
  <>
    <GrowthSmall />
    <GrowthLarge />
  </>
);
