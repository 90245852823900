import React from 'react';

import { StabilityLarge } from './StabilityLarge';
import { StabilitySmall } from './StabilitySmall';

export const Stability = () => (
  <>
    <StabilitySmall />
    <StabilityLarge />
  </>
);
