import { Badge } from 'component-library/components/badge/Badge';
import { ResponsiveBody } from 'component-library/components/typography/ResponsiveBody';
import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import { colors } from 'component-library/styles/colors';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionLarge } from 'page-components/shared/section/SectionLarge';
import React from 'react';
import styled from 'styled-components';

import { badge, body, heading } from './content';

const StyledSectionLarge = styled(SectionLarge)`
  margin-top: 0 !important;
`;

export const CashLarge = () => (
  <StyledSectionLarge
    backgroundColor={colors.green}
    image={<StaticImage src="../images/cash.webp" alt="cash-bucket" />}
    imageAlignment="left"
  >
    <Badge marginBottom={40}>{badge}</Badge>
    <ResponsiveHeading variant={2} marginBottom={24}>
      {heading}
    </ResponsiveHeading>
    <ResponsiveBody variant={1} marginBottom={48}>
      {body}
    </ResponsiveBody>
  </StyledSectionLarge>
);
