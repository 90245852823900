import Layout from 'common/layouts/Layout';
import { Navigation } from 'component-library/components/navigation/Navigation';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import { SEO } from 'components/SEO';
import { Cash, Growth, Hero, Stability } from 'page-components/investing';
import {
  featuresBody,
  FeaturesButton,
  featuresHeading,
  featuresImage,
  featuresValueProps,
} from 'page-components/investing/features/utils';
import {
  BottomCta,
  Footer,
  ValuePropGridSection,
} from 'page-components/shared';
import React, { FC } from 'react';

interface InvestingProps {}

const Investing: FC<InvestingProps> = () => (
  <Layout>
    <Navigation />
    <PageWrapper background="linear-gradient(180deg, #EBF8F2 0.31%, rgba(186, 225, 209, 0.26) 63.43%, rgba(255, 207, 173, 0.08) 76.99%, #EBF8F2 89.97%)">
      <Hero />
      <Cash />
      <Stability />
      <Growth />
      <ValuePropGridSection
        heading={featuresHeading}
        body={featuresBody}
        image={featuresImage}
        valueProps={featuresValueProps}
        Button={FeaturesButton}
      />
      <BottomCta
        heading="Invest in a better retirement."
        body="From greater peace of mind to ongoing care, we're ready to lend a hand."
      />
      <Footer />
    </PageWrapper>
  </Layout>
);

export default Investing;

export const Head = () => (
  <SEO
    title="Personalized Investments Strategies | Retirable"
    description="Investment strategies built to maximize tax efficiency, prepare for the future, and sustain your lifestyle no matter what happens in the market."
    imagePath="invest-featured.png"
  />
);
