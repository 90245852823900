import { colors } from 'component-library/styles/colors';
import { SpacingProps, spacingStyles } from 'component-library/styles/spacing';
import styled from 'styled-components';

export const Badge = styled.div<SpacingProps>`
  background: ${colors.peach};
  padding: 6px 14px;
  border-radius: 100px;
  width: fit-content;

  ${spacingStyles}
`;
