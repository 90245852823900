import { Badge } from 'component-library/components/badge/Badge';
import { ResponsiveBody } from 'component-library/components/typography/ResponsiveBody';
import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import { colors } from 'component-library/styles/colors';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

import { SectionLarge } from '../../shared/section/SectionLarge';
import { badge, body, heading } from './content';

export const StabilityLarge = () => (
  <SectionLarge
    backgroundColor={colors.teal}
    image={
      <StaticImage src="../images/stability.webp" alt="stability-bucket" />
    }
    imageAlignment="right"
  >
    <Badge marginBottom={40}>{badge}</Badge>
    <ResponsiveHeading variant={2} marginBottom={24}>
      {heading}
    </ResponsiveHeading>
    <ResponsiveBody variant={1} marginBottom={48}>
      {body}
    </ResponsiveBody>
  </SectionLarge>
);
