import {
  GetStartedButton,
  GetStartedButtonProps,
} from 'component-library/components/buttons/GetStartedButton';
import {
  Dynamic,
  Holistic,
  LowCost,
  Personalized,
  TaxEfficient,
  Trusted,
} from 'component-library/components/icons';
import { StaticImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';

export const featuresHeading = 'Investment Features';

export const featuresBody =
  "Our investment strategy is designed to meet your needs in retirement, while protecting what you've earned.";

export const FeaturesButton: FC<GetStartedButtonProps> = (props) => (
  <GetStartedButton {...props} />
);

export const featuresImage = (
  <StaticImage src="../images/investment-features.webp" alt="couple-dancing" />
);

export const featuresValueProps = [
  {
    heading: 'Personalized',
    body:
      'Your portfolio is designed for your goals, needs, and risk tolerance.',
    icon: <Personalized />,
  },
  {
    heading: 'Tax Efficient',
    body:
      'We help minimize taxes through trading, reinvesting dividends, and RMDs.',
    icon: <TaxEfficient />,
  },
  {
    heading: 'Dynamic',
    body:
      "We plan for inflation and ensure you're drawing from the bucket at the right time.",
    icon: <Dynamic />,
  },
  {
    heading: 'Holistic',
    body:
      'Our portfolios work alongside Social Security and your other income streams.',
    icon: <Holistic />,
  },
  {
    heading: 'Low Cost',
    body:
      'We have industry-low expense ratios with no hidden fees or trading fees.',
    icon: <LowCost />,
  },
  {
    heading: 'Trusted',
    body:
      'We invest in ETFs from firms you know, with no kickbacks from fund providers.',
    icon: <Trusted />,
  },
];
