import { Badge } from 'component-library/components/badge/Badge';
import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { colors } from 'component-library/styles/colors';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionSmall } from 'page-components/shared/section/SectionSmall';
import React from 'react';

import { badge, body, heading } from './content';

export const StabilitySmall = () => (
  <SectionSmall
    backgroundColor={colors.teal}
    image={
      <StaticImage src="../images/stability.webp" alt="stability-bucket" />
    }
  >
    <Badge marginBottom={24}>{badge}</Badge>
    <Heading marginBottom={16} variant={4}>
      {heading}
    </Heading>
    <Body variant={3}>{body}</Body>
  </SectionSmall>
);
