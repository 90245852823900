import { GetStartedButton } from 'component-library/components/buttons/GetStartedButton';
import { Flex } from 'component-library/components/layout/Flex';
import { ResponsiveBody } from 'component-library/components/typography/ResponsiveBody';
import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { device } from 'component-library/styles/device';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';

import { HeroProduct } from '../images/HeroProduct';
import { body, heading, secondHeading } from './content';

const HeroWrapper = styled(ShowOn)`
  max-width: 1568px;
  margin: 0 auto;
  padding: 120px 40px 0;
`;

const Text = styled(Flex)`
  width: 63%;
`;

const Image = styled.div`
  position: relative;
  width: 37%;
  margin-top: 70px;
`;

const Wrapper = styled(Flex)`
  gap: 40px;
`;

const Pattern = styled.div`
  margin-left: -9%;
  width: 93%;

  ${`@media ${device.xl}`} {
    margin-left: -7%;
  }
`;

const SecondHeading = styled(Flex)`
  height: 100%;
`;

const ProductImage = styled.div`
  position: absolute;
  width: 62%;
  z-index: 1;
  top: 45%;
  left: -39%;
`;

export const HeroLarge = () => (
  <HeroWrapper screens={[Screen.Lg, Screen.Xl, Screen.Xxl]}>
    <Wrapper>
      <Text column>
        <ResponsiveHeading variant={1} marginBottom={24}>
          {heading}
        </ResponsiveHeading>
        <ResponsiveBody maxWidth={685} variant={1} marginBottom={48}>
          {body}
        </ResponsiveBody>
        <GetStartedButton marginBottom={48} />
        <Pattern>
          <StaticImage
            src="../images/investing-hero-pattern-2.webp"
            alt="pattern"
          />
        </Pattern>
        <SecondHeading alignItems="center" marginTop={40} marginBottom={40}>
          <ResponsiveHeading variant={3}>{secondHeading}</ResponsiveHeading>
        </SecondHeading>
      </Text>
      <Image>
        <StaticImage src="../images/investing-hero.webp" alt="couple-smiling" />
        <ProductImage>
          <HeroProduct width="100%" height="100%" />
        </ProductImage>
        <StaticImage
          src="../images/investing-hero-pattern.webp"
          alt="pattern"
        />
      </Image>
    </Wrapper>
  </HeroWrapper>
);
