import { GetStartedButton } from 'component-library/components/buttons/GetStartedButton';
import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';

import { HeroProduct } from '../images/HeroProduct';
import { body, heading, secondHeading } from './content';

const HeroWrapper = styled(ShowOn)`
  padding: 56px 20px 80px;
`;

const Image = styled.div`
  max-width: 400px;
  margin: auto;
`;

const ProductScreenshot = styled.div`
  margin-top: -20%;
  margin-left: 10%;
  position: relative;
  width: 65%;
  z-index: 1;
`;

export const HeroSmall = () => (
  <HeroWrapper screens={[Screen.Sm, Screen.Md]}>
    <Heading variant={4} marginBottom={16}>
      {heading}
    </Heading>
    <Body marginBottom={32} variant={4}>
      {body}
    </Body>
    <GetStartedButton marginBottom={40} />
    <Image>
      <StaticImage src="../images/investing-hero.webp" alt="couple-smiling" />
      <ProductScreenshot>
        <HeroProduct width="100%" height="100%" />
      </ProductScreenshot>
    </Image>
    <Heading variant={4} marginTop={64}>
      {secondHeading}
    </Heading>
  </HeroWrapper>
);
